import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

//Noticias
import Tema1 from "layouts/pages/noticias/temas/tema1";
import Tema6 from "layouts/pages/noticias/temas/tema6";
import Tema2 from "layouts/pages/noticias/temas/tema2";
import Tema3 from "layouts/pages/noticias/temas/tema3";
import Tema4 from "layouts/pages/noticias/temas/tema4";
import Tema5 from "layouts/pages/noticias/temas/tema5";

//Rutas
import Noticias from "layouts/pages/noticias";
import Libro_reclamaciones from "layouts/pages/libro-reclamaciones";
import CarreraProfesional from "layouts/pages/general-carreras";
import Idiomas from "layouts/pages/idiomas";

// Material Kit 2 React routes
import routes from "routes";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/presentation" element={<Presentation />} />
        <Route path="*" element={<Navigate to="/presentation" />} />
        <Route path="/libro-de-reclamaciones" element={<Libro_reclamaciones />} />
        <Route path="*" element={<Navigate to="/libro-de-reclamaciones" />} />
        <Route path="/noticias/tema-noticia-1" element={<Tema1 />} />
        <Route path="*" element={<Navigate to="/noticias/tema-noticia-1" />} />
        <Route path="/noticias/tema-noticia-2" element={<Tema2 />} />
        <Route path="*" element={<Navigate to="/noticias/tema-noticia-2" />} />
        <Route path="/noticias/tema-noticia-3" element={<Tema3 />} />
        <Route path="*" element={<Navigate to="/noticias/tema-noticia-3" />} />
        <Route path="/noticias/tema-noticia-4" element={<Tema4 />} />
        <Route path="*" element={<Navigate to="/noticias/tema-noticia-4" />} />
        <Route path="/noticias/tema-noticia-5" element={<Tema5 />} />
        <Route path="*" element={<Navigate to="/noticias/tema-noticia-5" />} />
        <Route path="/noticias/tema-noticia-6" element={<Tema6 />} />
        <Route path="*" element={<Navigate to="/noticias/tema-noticia-6" />} />
        <Route path="/noticias" element={<Noticias />} />
        <Route path="*" element={<Navigate to="/noticias" />} />
        <Route path="/idiomas" element={<Idiomas />} />
        <Route path="*" element={<Navigate to="/idiomas" />} />
        <Route path="/carreras-profesionales" element={<CarreraProfesional />} />
        <Route path="*" element={<Navigate to="/carreras-profesionales" />} />
      </Routes>
    </ThemeProvider>
  );
}
