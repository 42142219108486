import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import libro from "assets/images/img-libro-reclamaciones.png";

function DefaultFooter({ content }) {
  const { brand, socials, menus, copyright } = content;

  return (
    <MKBox component="footer" sx={{ bgcolor: "rgb(0, 65, 101)" }}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ ml: "auto", mb: 3 }}>
            <MKBox>
              <Link to={brand.route}>
                <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="4rem" mb={2} />
              </Link>
              <MKTypography color="white" variant="h5">
                {brand.name}
              </MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" mt={3}>
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h3"
                  color="white"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={6} md={2} mt={5} sx={{ mb: 3 }}>
              <MKTypography
                display="block"
                variant="h6"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
                color="white"
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <MKTypography
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                        color="white"
                      >
                        {name}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        component={Link}
                        to={route}
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
          {/* Agregar las cuatro cajas aquí */}
          <Grid item xs={12} sx={{ textAlign: "center", my: 0 }}>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKBox mt={5} mr={{ md: 2 }} mb={{ xs: 2, md: 0 }}>
                <Link to={"/libro-de-reclamaciones"}>
                  <MKBox
                    component="img"
                    src={libro}
                    maxWidth="10rem"
                    mb={2}
                    ml={{
                      xs: 2,
                      md: -90,
                    }}
                  />
                </Link>
              </MKBox>
              <MKBox bgcolor="blue" p={2} ml={{ xs: 0, md: 0 }} sx={{ minWidth: "10rem" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <MKTypography variant="h6" fontWeight="regular" color="white">
                    ☏ Tel. (01) 648 3859
                  </MKTypography>
                  <MKTypography variant="h6" fontWeight="regular" color="white">
                    ✉ Email: worldnetinstitute@gmail.com
                  </MKTypography>
                  <MKTypography variant="h6" fontWeight="regular" color="white">
                    🕐︎ Horarios: 6:00 a.m - 9:30 p.m
                  </MKTypography>
                  <MKTypography variant="h6" fontWeight="regular" color="white">
                    🏳 RUC: 00000000000
                  </MKTypography>
                </div>
              </MKBox>
            </MKBox>
          </Grid>
          {/* Fin de las cuatro cajas */}
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
