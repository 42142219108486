import React from "react";
import mipdf from "transparencia/becas-y-creditos/pdf/documento.pdf";
import "transparencia/gestion-institucional/styles/styles.css";

function PdfViewer() {
  const handleDownload = () => {
    const fileName = "prueba.pdf";
    const downloadLink = document.createElement("a");
    downloadLink.href = mipdf;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <iframe
        src={mipdf}
        width="80%"
        height="800"
        title="PDF Viewer"
        style={{
          marginBottom: "20px",
        }}
      ></iframe>
      <div>
        <button
          onClick={handleDownload}
          style={{
            backgroundColor: "#007bff",
            color: "#fff",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            transition: "background-color 0.8s, transform 0.3s",
            fontSize: "16px",
          }}
          className="download-btn"
        >
          Descargar PDF
        </button>
      </div>
    </div>
  );
}

export default PdfViewer;
