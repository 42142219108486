import bgImage1 from "assets/images/card-admision.png";
import bgImage2 from "assets/images/card-reingresos.png";
import bgImage3 from "assets/images/card-translados.png";

export default [
  {
    items: [
      {
        image: `${bgImage1}`,
        name: "+Información",
        route: "/carreras-profesionales",
        Tema: "Admisión",
        Subtema: "Para todos los interesados en nuestras carreras.",
      },
      {
        image: `${bgImage2}`,
        name: "+Información",
        route: "/pages/generalidades/modalidades-de-estudio",
        Tema: "Reingresos",
        Subtema: "Facilidades para quienes quieren retomar sus estudios.",
      },
      {
        image: `${bgImage3}`,
        name: "+Información",
        route: "/pages/generalidades/beneficios-academicos",
        Tema: "Transalos",
        Subtema: "Promociones especiales para quienes vienen de otras instituciones.",
      },
    ],
  },
];
