import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Box from "@mui/material/Box";

function Section6() {
  return (
    <MKBox component="section" pt={6} my={5}>
      <Container>
        <Grid
          container
          justifyContent="center" // Centrar horizontalmente
          alignItems="center" // Centrar verticalmente
          flexDirection="column" // Alinear los elementos en columna
        >
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <MKTypography variant="h2" mb={2} mt={0} textAlign="center">
              ¿Por qué estudiar Administración Bancaria?
            </MKTypography>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px",
                height: "8px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
        <Grid
          container
          justifyContent="center" // Centrar horizontalmente
          alignItems="center" // Centrar verticalmente
          flexDirection="column" // Alinear los elementos en columna
          my={8}
        >
          <Grid item xs={12} my={-5} md={6} sx={{ ml: { xs: 0, lg: 0 }, mb: { xs: 3, md: 0 } }}>
            <MKTypography variant="body2" color="black" mb={4} my={5}>
              <ul>
                <li>
                  Es una profesión integral que ofrece múltiples opciones de especialización y te
                  permitirá desenvolverte en distintas áreas administrativas y comerciales.
                </li>
                <li>
                  Las oportunidades de empleo se extienden por todo el país en cualquier empresa,
                  mientras la economía siga mostrándose con gran solidez.
                </li>
                <li>
                  Según tu preparación y experiencia tienes la posibilidad de optar por trabajo en
                  el extranjero, ya que el alcance del sector financiero es global.
                </li>
                <li> Puedes ejercerla de manera dependiente o independiente. </li>
              </ul>
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Section6;
