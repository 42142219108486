import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Box from "@mui/material/Box";
import imagen1 from "assets/images/img-mision.png";
import imagen2 from "assets/images/img-vision.png";
import imagen3 from "assets/images/img-valores.png";

function Section3() {
  const [boxes, setBoxes] = useState([
    {
      backColor: "rgb(11, 15, 91)",
      backText:
        "Cada estudiante es parte fundamental de una comunidad que valora la excelencia, la ética y la responsabilidad social. A medida que avanzan en su formación, los instamos a no solo aspirar al éxito personal, sino a comprometerse activamente en la construcción de un Perú más justo, equitativo y próspero.",
      backTextSize: "1rem",
      image: imagen1,
      showImageOnFront: true,
      isHovered: false,
    },
    {
      backColor: "rgb(11, 15, 91)",
      backText:
        "Cada estudiante es una parte fundamental de una comunidad que valora la excelencia, la ética y la responsabilidad social. A medida que avanzan en su formación, los instamos a no solo aspirar al éxito personal, sino también a comprometerse activamente en la construcción de un Perú más justo, equitativo y próspero.",
      backTextSize: "1rem",
      image: imagen2,
      showImageOnFront: true,
      isHovered: false,
    },
    {
      backColor: "rgb(11, 15, 91)",
      backText:
        "Excelencia, Ética, Responsabilidad social, Compromiso, Justicia, Equidad, Prosperidad.",
      backTextSize: "1rem",
      image: imagen3,
      showImageOnFront: true,
      isHovered: false,
    },
  ]);

  const handleMouseEnter = (index) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box, i) => (i === index ? { ...box, isHovered: true } : box))
    );
  };

  const handleMouseLeave = (index) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box, i) => (i === index ? { ...box, isHovered: false } : box))
    );
  };

  return (
    <MKBox component="section" pt="3rem" pb="8rem">
      <Container>
        <Grid container justifyContent="center" spacing={2} my={-3}>
          {boxes.map((box, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
                sx={{
                  width: "20rem",
                  height: "25rem",
                  borderRadius: "1rem",
                  backgroundColor: box.isHovered ? box.backColor : box.frontColor,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1rem",
                  position: "relative",
                  overflow: "hidden",
                  textAlign: "center",
                }}
              >
                {box.showImageOnFront && !box.isHovered && (
                  <img
                    src={box.image}
                    style={{
                      maxWidth: "20rem",
                      maxHeight: "30rem",
                      objectFit: "contain",
                      zIndex: 1,
                    }}
                    alt={box.backText}
                  />
                )}
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%", // Ajusta el ancho del texto al 90% del contenedor
                    textAlign: "center",
                    zIndex: 2,
                  }}
                >
                  <MKTypography
                    variant="h6"
                    fontWeight="bold"
                    sx={{
                      fontSize: box.isHovered ? box.backTextSize : box.frontTextSize,
                      color: "white !important",
                    }}
                  >
                    {box.isHovered ? box.backText : ""}
                  </MKTypography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Section3;
