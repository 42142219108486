import React from "react";

// Importa los componentes de las secciones
import Nosotros from "layouts/pages/nosotros-ly";
import Pq_World_Net from "layouts/pages/por-que-world";
import Administracion_bancaria from "layouts/pages/carreras-profesionales/adm-bancaria";
import Asistente_administrativo from "layouts/pages/carreras-profesionales/asis-administrativo";
import Computacion from "layouts/pages/carreras-profesionales/computacion";
import Secretariado_bilingue from "layouts/pages/carreras-profesionales/secre-bilingue";
import Administracion_marketing from "layouts/pages/carreras-profesionales/adm-marketing";
import Blogs from "layouts/pages/blog";
import Gastronomia from "layouts/pages/carreras-profesionales/gastronomia";
import Educacion_continua from "layouts/pages/educacion-continua";

//Transparencia
import Becas_creditos from "layouts/pages/transparencia/becas-y-creditos";
import Tupa from "layouts/pages/transparencia/tupa";
import Proyectos_investigacion from "layouts/pages/transparencia/proyectos-investigacion";
import Gestion_institucional from "layouts/pages/transparencia/gestion-institucional";
import Plana_docente from "layouts/pages/transparencia/plana-docencte";
import Reglamento_institucional from "layouts/pages/transparencia/reglamento-institucional";
import Procedimientos_administrativos from "layouts/pages/transparencia/procedimientos-administrativos";
import Obras_infraestructuras from "layouts/pages/transparencia/obras-de-infraestructura-inversiones-y-donaciones";
import Ingresantes_matriculados from "layouts/pages/transparencia/ingresantes-matriculados-y-egresados";
import Proceso_matricula from "layouts/pages/transparencia/proceso-de-matricula-y-horarios-por-programa";

// Definición de las rutas
const routes = [
  {
    name: "Nosotros",
    route: "/nosotros",
    component: <Nosotros />,
  },
  {
    name: "¿Por qué World Net?",
    route: "/por-que-estudiar-en-world-net",
    component: <Pq_World_Net />,
  },
  {
    name: "Carreras",
    collapse: [
      {
        name: "Administración Bancaria",
        route: "/carreras/administracion-bancaria",
        component: <Administracion_bancaria />,
      },
      {
        name: "Administración y Marketing",
        route: "/carreras/administracion-y-marketing",
        component: <Administracion_marketing />,
      },
      {
        name: "Asistente Administrativo Bilingue",
        route: "/carreras/asistente-admnistrativo-bilingue",
        component: <Asistente_administrativo />,
      },
      {
        name: "Computación",
        route: "/carreras/computacion",
        component: <Computacion />,
      },
      {
        name: "Gatronomía",
        route: "/carreras/gastronomia",
        component: <Gastronomia />,
      },
      {
        name: "Secretariado Bilingue",
        route: "/carreras/secretariado-bilingue",
        component: <Secretariado_bilingue />,
      },
    ],
  },
  {
    name: "Educación Continua",
    route: "/educacion-continua",
    component: <Educacion_continua />,
  },
  {
    name: "Experiencia World Net",
    route: "/noticias",
    component: <Blogs />,
  },
  {
    name: "Transparencia",
    collapse: [
      {
        name: "Becas y Créditos",
        route: "/transparencia/becas-y-creditos",
        component: <Becas_creditos />,
      },
      {
        name: "TUPA",
        route: "/transparencia/tupa",
        component: <Tupa />,
      },
      {
        name: "Proyectos de Investigación",
        route: "/transparencia/proyectos-de-investigacion",
        component: <Proyectos_investigacion />,
      },
      {
        name: "Plana Docente",
        route: "/transparencia/plana-docente",
        component: <Plana_docente />,
      },
      {
        name: "Ingresantes, Matriculados y Egresados",
        route: "/transparencia/ingresantes-matriculados-y-egresados",
        component: <Ingresantes_matriculados />,
      },
      {
        name: "Proceso de matricula y horarios por programa",
        route: "/transparencia/proceso-de-matricula-y-horarios-por-programa",
        component: <Proceso_matricula />,
      },
      {
        name: "Reglamento Institucional",
        route: "/transparencia/reglamento-institucional",
        component: <Reglamento_institucional />,
      },
      {
        name: "Gestión Institucional",
        route: "/transparencia/gestion-institucional",
        component: <Gestion_institucional />,
      },
      {
        name: "Procedimientos Administratitivos",
        route: "/transparencia/procedimientos-administrativos",
        component: <Procedimientos_administrativos />,
      },
      {
        name: "Obras de Infraestructura, inversiones y donaciones",
        route: "/transparencia/obras-de-infraestructura-inversiones-y-donaciones",
        component: <Obras_infraestructuras />,
      },
    ],
  },
];

export default routes;
