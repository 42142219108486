//imagenes
import bgImage1 from "assets/images/card-noticia.png";
import bgImage2 from "assets/images/card-noticia.png";
import bgImage3 from "assets/images/card-noticia.png";

export default [
  {
    items: [
      {
        image: `${bgImage1}`,
        name: "Leer más",
        route: "/noticias/tema-noticia-1",
        text_fecha: "10 de Febrero del 2024",
        text_tema: "Titulo de la noticia",
      },
      {
        image: `${bgImage2}`,
        name: "Leer más",
        route: "/noticias/tema-noticia-2",
        text_fecha: "10 de Febrero del 2024",
        text_tema: "Titulo de la noticia",
      },
      {
        image: `${bgImage3}`,
        name: "Leer más",
        route: "/noticias/tema-noticia-3",
        text_fecha: "10 de Febrero del 2024",
        text_tema: "Titulo de la noticia",
      },
      {
        image: `${bgImage3}`,
        name: "Leer más",
        route: "/noticias/tema-noticia-4",
        text_fecha: "10 de Febrero del 2024",
        text_tema: "Titulo de la noticia",
      },
      {
        image: `${bgImage3}`,
        name: "Leer más",
        route: "/noticias/tema-noticia-5",
        text_fecha: "10 de Febrero del 2024",
        text_tema: "Titulo de la noticia",
      },
      {
        image: `${bgImage3}`,
        name: "Leer más",
        route: "/noticias/tema-noticia-6",
        text_fecha: "10 de Febrero del 2024",
        text_tema: "Titulo de la noticia",
      },
    ],
  },
];
