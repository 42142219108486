import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import icon1 from "assets/images/iconos/icon-fecha.png";
import icon2 from "assets/images/iconos/icon-horario.png";
import icon3 from "assets/images/iconos/icon-modalidad.png";

function Section1() {
  return (
    <MKBox component="section" pt={6} my={-3}>
      <Container>
        <Grid
          container
          justifyContent="center" // Centrar horizontalmente
          alignItems="center" // Centrar verticalmente
          flexDirection="column" // Alinear los elementos en columna
        >
          <Box sx={{ position: "relative", display: "inline-block", textAlign: "center" }}>
            <MKTypography variant="h1" mb={2} mt={0}>
              Administración bancaria
            </MKTypography>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px",
                height: "8px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
        <Grid container my={4} justifyContent="center">
          <Grid item xs={12} md={8.8} sx={{ ml: { xs: 0, lg: 0 }, mb: { xs: 3, md: 0 } }}>
            <MKTypography variant="body2" color="black" mb={4} my={5} textAlign="center">
              Esta disciplina se enfoca en identificar, analizar y gestionar procesos
              administrativos y operaciones financieras esenciales en todas las organizaciones. Los
              profesionales en este campo combinan habilidades de gestión con una eficiente
              administración de recursos. Está estrechamente relacionada con las áreas de finanzas,
              negocios y marketing, lo que permite una visión completa que aborda tanto la
              eficiencia interna como la relación con el mercado. Este enfoque integral capacita a
              los profesionales para enfrentar los desafíos del entorno empresarial actual,
              asegurando una gestión efectiva y sostenible en diversas áreas organizativas.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
      <Grid container my={-5} spacing={5} justifyContent="center">
        <Grid item xs={10} sm={6} md={3} lg={2}>
          <Box
            sx={{
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "1rem",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)",
            }}
          >
            <img
              src={icon1}
              style={{
                maxWidth: "100%",
                height: "auto",
                maxHeight: "4rem",
                marginBottom: "1.8rem",
              }}
            />
            <MKTypography variant="h5" color="black" mb={1} textAlign="center">
              Inicio
            </MKTypography>
            <MKTypography variant="h6" color="black" mb={2} textAlign="center" fontSize="0.8rem">
              25 de Marzo
            </MKTypography>
          </Box>
        </Grid>
        <Grid item xs={10} sm={6} md={3} lg={2}>
          <Box
            sx={{
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "1rem",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)",
            }}
          >
            <img
              src={icon2}
              style={{
                maxWidth: "100%",
                height: "auto",
                maxHeight: "4rem",
                marginBottom: "0.8rem",
              }}
            />
            <MKTypography variant="h5" color="black" mb={0.5} textAlign="center">
              Turno
            </MKTypography>
            <MKTypography variant="h6" color="black" mb={2} textAlign="center" fontSize="0.8rem">
              Mañana / Tarde / Noche (6:00 a.m - 9:30 p.m)
            </MKTypography>
          </Box>
        </Grid>
        <Grid item xs={10} sm={6} md={3} lg={2}>
          <Box
            sx={{
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "1rem",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)",
            }}
          >
            <img
              src={icon3}
              style={{
                maxWidth: "100%",
                height: "auto",
                maxHeight: "4rem",
                marginBottom: "1.9rem",
              }}
            />
            <MKTypography variant="h5" color="black" mb={0.5} textAlign="center">
              Modalidad
            </MKTypography>
            <MKTypography variant="h6" color="black" mb={2} textAlign="center" fontSize="0.8rem">
              Presencial y virtual
            </MKTypography>
          </Box>
        </Grid>
        <Grid item xs={10} sm={6} md={3} lg={2}>
          <Box
            sx={{
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "1rem",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)",
            }}
          >
            <img
              src={icon1}
              style={{
                maxWidth: "100%",
                height: "auto",
                maxHeight: "4rem",
                marginBottom: "1rem",
              }}
            />
            <MKTypography variant="h5" color="black" mb={0.5} textAlign="center">
              Duración
            </MKTypography>
            <MKTypography variant="h6" color="black" mb={0} textAlign="center" fontSize="0.9rem">
              2 años (3 veces a la semana)
            </MKTypography>
          </Box>
        </Grid>
      </Grid>
      <Box mt={5} />
    </MKBox>
  );
}

export default Section1;
