import React, { useRef, useState } from "react";
import Slider from "react-slick";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MKTypography from "components/MKTypography";
import "nosotros/styles/Carousel.css";
import image1 from "assets/images/testimonios/img-testimonios.png";
import image2 from "assets/images/testimonios/img-testimonios.png";
import image3 from "assets/images/testimonios/img-testimonios.png";

const Section3 = () => {
  const sliderRef = useRef(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const testimonios = [
    {
      image: image1,
      text: "Testimonio 1",
      additionalText:
        "El Instituto Técnico Productivo World Net se distingue por su compromiso inquebrantable en la formación de profesionales excepcionales. Nuestra misión es proporcionar a nuestros estudiantes las herramientas necesarias para convertirse no solo en expertos en sus campos de estudio, sino también en ciudadanos ejemplares. Creemos fervientemente en la capacidad de la educación para contribuir a la construcción de un país justo, libre, competitivo y abierto al mundo.",
      column1: {
        text1: "Nombres y Apellidos",
        text2: "",
      },
      column2: {
        text1: "Trabajo",
        text2: "Cargo",
      },
    },
    {
      image: image2,
      text: "Testimonio 2",
      additionalText:
        "El Instituto Técnico Productivo World Net se distingue por su compromiso inquebrantable en la formación de profesionales excepcionales. Nuestra misión es proporcionar a nuestros estudiantes las herramientas necesarias para convertirse no solo en expertos en sus campos de estudio, sino también en ciudadanos ejemplares. Creemos fervientemente en la capacidad de la educación para contribuir a la construcción de un país justo, libre, competitivo y abierto al mundo.",
      column1: {
        text1: "Nombres y Apellidos",
        text2: "",
      },
      column2: {
        text1: "Trabajo",
        text2: "Cargo",
      },
    },
    {
      image: image3,
      text: "Testimonio 3",
      additionalText:
        "El Instituto Técnico Productivo World Net se distingue por su compromiso inquebrantable en la formación de profesionales excepcionales. Nuestra misión es proporcionar a nuestros estudiantes las herramientas necesarias para convertirse no solo en expertos en sus campos de estudio, sino también en ciudadanos ejemplares. Creemos fervientemente en la capacidad de la educación para contribuir a la construcción de un país justo, libre, competitivo y abierto al mundo.",
      column1: {
        text1: "Nombres y Apellidos",
        text2: "",
      },
      column2: {
        text1: "Trabajo",
        text2: "Cargo",
      },
    },
  ];

  return (
    <div className="carousel-container">
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 5, mx: "auto", px: 0.75 }}
        >
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <MKTypography variant="h2" fontWeight="bold" my={1}>
              Más Testimonios
            </MKTypography>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px",
                height: "10px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
      </Container>
      <div className="carousel-wrapper">
        <Slider {...settings} ref={sliderRef}>
          {testimonios.map((testimonio, index) => (
            <div
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <img src={testimonio.image} alt={`Slide ${index + 1}`} />
              {hoveredIndex === index && (
                <div className="new-additional-text-box">
                  <p>{testimonio.additionalText}</p>
                  <Grid container mt={3}>
                    <Grid item xs={6}>
                      <p>{testimonio.column1.text1}</p>
                      <p>{testimonio.column1.text2}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <p>{testimonio.column2.text1}</p>
                      <p>{testimonio.column2.text2}</p>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          ))}
        </Slider>
        <button className="carousel-btn prev" onClick={goToPrev}></button>
        <button className="carousel-btn next" onClick={goToNext}></button>
      </div>
    </div>
  );
};

export default Section3;
