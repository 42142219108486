import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Box from "@mui/material/Box";

function Section6() {
  return (
    <MKBox component="section" pt={6} my={5}>
      <Container>
        <Grid
          container
          justifyContent="center" // Centrar horizontalmente
          alignItems="center" // Centrar verticalmente
          flexDirection="column" // Alinear los elementos en columna
        >
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <MKTypography variant="h2" mb={2} mt={0} textAlign="center">
              Practicas
            </MKTypography>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px",
                height: "8px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
        <Grid
          container
          justifyContent="center" // Centrar horizontalmente
          alignItems="center" // Centrar verticalmente
          flexDirection="column" // Alinear los elementos en columna
          my={8}
        >
          <Grid item xs={12} my={-5} md={6} sx={{ ml: { xs: 0, lg: 0 }, mb: { xs: 3, md: 0 } }}>
            <MKTypography variant="body2" color="black" mb={4} my={5}>
              <ul>
                <li> Secretaria de Instituciones Públicas o Privadas. </li>
                <li>
                  Ejecutivo en el área de marketing en empresas industriales comerciales y de
                  servicios.
                </li>
                <li>Áreas de Call Center. </li>
                <li> Áreas de Servicio al Cliente. </li>
                <li> Áreas de Investigación y Desarrollo de Proyectos de Inversión. </li>
                <li> Áreas de Relaciones Públicas. </li>
              </ul>
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Section6;
