import React from "react";
import PropTypes from "prop-types";
import pdfPerson1 from "transparencia/plana-docente/pdf/documento.pdf";
import pdfPerson2 from "transparencia/plana-docente/pdf/documento.pdf";
import pdfPerson3 from "transparencia/plana-docente/pdf/documento.pdf";
import pdfPerson4 from "transparencia/plana-docente/pdf/documento.pdf";
import pdfPerson5 from "transparencia/plana-docente/pdf/documento.pdf";
import pdfPerson6 from "transparencia/plana-docente/pdf/documento.pdf";

import "transparencia/plana-docente/styles/styles.css";

const personas = [
  { nombre: "Persona 1", pdf: pdfPerson1 },
  { nombre: "Persona 2", pdf: pdfPerson2 },
  { nombre: "Persona 3", pdf: pdfPerson3 },
  { nombre: "Persona 4", pdf: pdfPerson4 },
  { nombre: "Persona 5", pdf: pdfPerson5 },
  { nombre: "Persona 6", pdf: pdfPerson6 },
];

function PdfViewer({ pdf }) {
  const handleOpenPdf = () => {
    window.open(pdf, "_blank");
  };

  return <button onClick={handleOpenPdf}> Visualizar PDF </button>;
}

PdfViewer.propTypes = {
  pdf: PropTypes.string.isRequired,
};

function TablaPersonas() {
  return (
    <div className="container">
      <table className="table">
        <thead>
          <tr>
            <th>Nombres y Apellidos</th>
            <th>Hoja de vida</th>
          </tr>
        </thead>
        <tbody>
          {personas.map((persona, index) => (
            <tr key={index}>
              <td>{persona.nombre}</td>
              <td>
                <PdfViewer pdf={persona.pdf} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TablaPersonas;
