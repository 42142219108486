import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Box from "@mui/material/Box";
import icon1 from "assets/images/iconos/icon-estudiantes.png";
import icon2 from "assets/images/iconos/icon-docentes.png";
import icon3 from "assets/images/iconos/icon-egresados.png";

function Section1() {
  return (
    <MKBox component="section" pt={6} my={-3}>
      <Container>
        <Grid container spacing={0} justifyContent="center">
          {/* Primera caja */}
          <Grid item xs={12} sm={8} md={4}>
            <Grid container justifyContent="center">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                  width: "20rem",
                  mt: 5,
                  padding: "20px",
                  boxSizing: "border-box",
                }}
              >
                <img
                  src={icon1}
                  style={{
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "50%",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                  }}
                />
                <Box ml={2}>
                  <MKTypography variant="h1" color="black">
                    +1,000
                  </MKTypography>
                  <MKTypography variant="h5" color="black">
                    Alumnos
                  </MKTypography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container justifyContent="center">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                  width: "20rem",
                  mt: 5,
                  padding: "20px",
                  boxSizing: "border-box",
                }}
              >
                <img
                  src={icon2}
                  style={{
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "50%",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                  }}
                />
                <Box ml={2}>
                  <MKTypography variant="h1" color="black">
                    +100
                  </MKTypography>
                  <MKTypography variant="h5" color="black">
                    Docentes
                  </MKTypography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* Tercera caja */}
          <Grid item xs={12} sm={6} md={4}>
            <Grid container justifyContent="center">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                  width: "20rem",
                  mt: 5,
                  padding: "20px",
                  boxSizing: "border-box",
                }}
              >
                <img
                  src={icon3}
                  style={{
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "50%",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                  }}
                />
                <Box ml={2}>
                  <MKTypography variant="h1" color="black">
                    +8,000
                  </MKTypography>
                  <MKTypography variant="h5" color="black">
                    Egresados
                  </MKTypography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Section1;
