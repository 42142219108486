import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Box from "@mui/material/Box";

// Images
import logo from "assets/images/logo-instituto.png";

function Section2() {
  return (
    <MKBox component="section" pt={6} my={15}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 0, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h6" fontWeight="bolds">
            Nuestra
          </MKTypography>
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <MKTypography variant="h2" fontWeight="bold" my={1}>
              Historia
            </MKTypography>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px",
                height: "10px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
        <Grid
          container
          alignItems="flex-start"
          my={13}
          x={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <Grid item xs={12} my={-15} md={5} sx={{ ml: { xs: 0, lg: 25 }, mb: { xs: 3, md: 0 } }}>
            <MKTypography variant="body2" color="black" mb={4} my={12}>
              El Instituto WorldNet fue fundado el 00 de octubre con la finalidad de formar Técnicos
              de calidad. Esta nueva administración esta liderada por nuestra directora Doctora Ruth
              Aguirre Romero natural de la zona centro, que muy sidentificada con la necesidad
              educativa de los jóvenes presenta una visión con propuesta innovadora, emprendedora y
              con muchas facilidades para la juventud. Actualmente contamos con una implementada
              plataforma en la cual brindamos nuestras carreras técnicas profesional autorizado por
              el Ministerio de Educación. Así como otras opciones técnicas, de gran habilidad en
              nuestra sociedad y con necesidades laborales.
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ ml: "85px", mb: { xs: 4, md: 0 } }}>
            <MKBox mb={4} my={-3}>
              <MKBox
                component="img"
                src={logo}
                alt="macbook"
                width="15rem"
                sx={{
                  borderRadius: "12px",
                }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
      <Box mt={-29} />
    </MKBox>
  );
}

export default Section2;
