// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Newsletter() {
  return (
    <MKBox component="section" pt={6} my={1} textAlign="center">
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} mb={3} sx={{ ml: { xs: 0, lg: 0 }, mb: { xs: 3, md: 0 } }}>
            <MKTypography variant="body2" color="black" mb={8} my={5} textAlign="center">
              Si estás buscando una carrera profesional de 2 años, el Instituto WorldNet ofrece una
              formación práctica y completa que te prepara para enfrentar los desafíos del mundo
              laboral. Con una sólida base teórica y experiencia práctica en entornos reales de
              trabajo, nuestras carreras te brindan las habilidades necesarias para prosperar.
              <br /> <br />
              ¡Inscríbete ahora y comienza a forjar tu camino hacia el éxito!
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;
