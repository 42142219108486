import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Box from "@mui/material/Box";

function Section6() {
  return (
    <MKBox component="section" pt={6} my={0}>
      <Container>
        <Grid
          container
          justifyContent="center" // Centrar horizontalmente
          alignItems="center" // Centrar verticalmente
          flexDirection="column" // Alinear los elementos en columna
        >
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <MKTypography variant="h2" mb={2} mt={0} textAlign="center">
              ¿Por qué estudiar Gastronomía?
            </MKTypography>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px",
                height: "8px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
        <Grid
          container
          justifyContent="center" // Centrar horizontalmente
          alignItems="center" // Centrar verticalmente
          flexDirection="column" // Alinear los elementos en columna
          my={8}
        >
          <Grid item xs={12} my={-5} md={6} sx={{ ml: { xs: 0, lg: 0 }, mb: { xs: 3, md: 0 } }}>
            <MKTypography variant="body2" color="black" mb={4} my={5}>
              <ul>
                <li>
                  Empresas asesoras en conceptualización y gestión de marcas, diseño de estrategias
                  de marca, desarrollo de campañas de promoción y ventas en medios tradicionales,
                  alternativos y digitales.
                </li>
                <li>
                  Ejecutivo en el área de marketing en empresas industriales comerciales y de
                  servicios.
                </li>
                <li> Gerente de estrategias de comunicación e investigación de mercados. </li>
                <li> Gerente de Marketing que desarrolla unidades estratégicas de negocios. </li>
                <li>
                  Gerente de trade marketing, capaz de obtener valor en toda estrategia de canales
                  de distribución y retail que desarrolles.
                </li>
                <li>
                  Jefe de Marketing Digital, desarrollando estrategias a nivel de Redes Sociales,
                  SEO y SEM, que permitan una mayor efectividad en la campaña.
                </li>
              </ul>
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Section6;
