import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Box from "@mui/material/Box";

// Images
import notices from "assets/images/card-noticia.png";

function Section1() {
  return (
    <MKBox component="section" pt={6} my={5} ml={15}>
      <Container>
        <Box sx={{ position: "relative", display: "inline-block" }}>
          <MKTypography variant="h2" mb={2} mt={0}>
            Tema de la noticia 4
          </MKTypography>
          <Box
            sx={{
              position: "absolute",
              left: "5%",
              bottom: "-8px",
              transform: "translateX(-50%)",
              width: "50px",
              height: "8px",
              backgroundColor: "rgb(105, 190, 40)",
              borderRadius: "12px",
            }}
          />
        </Box>
        <Grid
          container
          alignItems="flex-start"
          my={9}
          x={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <Grid item xs={12} my={-11} md={6} sx={{ ml: { xs: 0, lg: 0 }, mb: { xs: 3, md: 0 } }}>
            <MKTypography variant="body2" color="black" mb={4} my={12}>
              Una noticia es un relato o reporte sobre un evento o suceso reciente que se considera
              relevante para el público. Las noticias pueden cubrir una amplia gama de temas,
              incluyendo política, economía, ciencia, cultura, deportes, entre otros. Las
              características principales de una noticia suelen incluir objetividad, imparcialidad,
              actualidad, veracidad y relevancia para la audiencia a la que se dirige.
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "85px", mb: { xs: 4, md: 0 } }}>
            <MKBox mb={4} my={-6}>
              <MKBox component="img" src={notices} alt="macbook" width="50%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Section1;
