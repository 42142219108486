import React from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Example_notice from "pages/Presentation/components/ExampleCard";
import data from "pages/Presentation/sections/data/designBlocksData4";

function Section5() {
  const renderData = data.map(({ title, description, items }) => (
    <Grid container spacing={3} sx={{ mb: 5 }} textAlign="center" key={title}>
      <Grid item xs={12} lg={1.5}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          {items.map(({ image, name, count, route, pro, text_fecha, text_tema }) => (
            <Grid item xs={12} md={4} sx={{ mb: 2 }} key={name}>
              <Link to={pro ? "/" : route}>
                <Box sx={{ position: "relative" }}>
                  <Example_notice image={image} count={count} pro={pro} />
                  <Box
                    sx={{
                      position: "absolute",
                      border: "1px solid white", // Definir el borde con un grosor específico
                      borderRadius: "25px",
                      bottom: 20,
                      left: 25,
                      right: 60,
                      backgroundColor: "white !important",
                      padding: "10px",
                      fontSize: "0.8rem",
                      "&:hover": {
                        backgroundColor: "rgb(0, 154, 166) !important",
                        color: "white !important",
                        borderColor: "rgb(0, 154, 166) !important",
                      },
                      "&:active": {
                        color: "white !important",
                      },
                    }}
                  >
                    <MKTypography
                      variant="h5"
                      fontWeight="bold"
                      style={{
                        fontSize: "0.9rem",
                      }}
                    >
                      {name}
                    </MKTypography>
                  </Box>
                </Box>
              </Link>
              <Box mt={1}>
                <MKTypography
                  variant="h6"
                  color="black"
                  textAlign="left"
                  style={{ opacity: 0.3 }} // Reducir la opacidad al 70%
                >
                  {text_fecha}
                </MKTypography>
              </Box>
              <Box mt={1}>
                <MKTypography variant="h5" color="black" textAlign="left">
                  {text_tema}
                </MKTypography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <MKBox component="section" my={0} py={0}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 0, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h6" fontWeight="bolds">
            Vive la
          </MKTypography>
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <MKTypography variant="h2" fontWeight="bold" my={1}>
              Experiencia World Net Institute
            </MKTypography>
            {/* Agregar la barra de color verde debajo del texto */}
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px", // ajustar según el ancho deseado
                height: "10px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
          <MKTypography variant="h4" fontWeight="bolds" my={5}>
            Noticias
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: 4 }}>{renderData}</Container>
      <Box textAlign="center" mt={-2}>
        <Link to="/noticias">
          <Button
            variant="outlined"
            sx={{
              borderColor: "rgb(0, 65, 101) !important",
              color: "rgb(0, 65, 101)",
              borderRadius: "25px",
              "&:hover": {
                backgroundColor: "rgb(0, 65, 101)",
                color: "white !important",
                borderColor: "rgb(0, 65, 101) !important",
              },
              "&:active": {
                color: "white !important",
              },
            }}
          >
            VER MÁS PUBLICACIONES
          </Button>
        </Link>
      </Box>
      <Box mt={15} />
    </MKBox>
  );
}

export default Section5;
