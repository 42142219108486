//imagenes
import bgImage1 from "assets/images/cards/card-administracion-b.png";
import bgImage2 from "assets/images/cards/card-administracion-m.png";
import bgImage3 from "assets/images/cards/card-asistente.png";
import bgImage4 from "assets/images/cards/card-computacion.png";
import bgImage5 from "assets/images/cards/card-gastronomia.png";
import bgImage6 from "assets/images/cards/card-secretariado-b.png";

export default [
  {
    items: [
      {
        image: `${bgImage1}`,
        name: "Más información",
        route: "/carreras/administracion-bancaria",
      },
      {
        image: `${bgImage2}`,
        name: "Más información",
        route: "/carreras/administracion-y-marketing",
      },
      {
        image: `${bgImage3}`,
        name: "Más información",
        route: "/carreras/asistente-admnistrativo-bilingue",
      },
      {
        image: `${bgImage4}`,
        name: "Más información",
        route: "/carreras/computacion",
      },
      {
        image: `${bgImage5}`,
        name: "Más información",
        route: "/carreras/gastronomia",
      },
      {
        image: `${bgImage6}`,
        name: "Más información",
        route: "/carreras/secretariado-bilingue",
      },
    ],
  },
];
