import React, { useRef } from "react";
import Slider from "react-slick";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MKTypography from "components/MKTypography";
import "nosotros/styles/Carrusel.css";
import image1 from "assets/images/img-directivo.png";
import image2 from "assets/images/img-directivo.png";
import image3 from "assets/images/img-directivo.png";

const Carousel = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className="carousel-container">
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 5, mx: "auto", px: 0.75 }}
        >
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <MKTypography variant="h2" fontWeight="bold" my={1}>
              Docentes
            </MKTypography>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px",
                height: "10px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
      </Container>
      <div className="carousel-wrapper">
        <Slider {...settings} ref={sliderRef}>
          <div>
            <img src={image1} alt="Slide 1" />
          </div>
          <div>
            <img src={image1} alt="Slide 2" />
          </div>
          <div>
            <img src={image2} alt="Slide 3" />
          </div>
          <div>
            <img src={image3} alt="Slide 4" />
          </div>
          {/* Agrega más elementos div para más slides */}
        </Slider>
        <button className="carousel-btn prev" onClick={goToPrev}></button>
        <button className="carousel-btn next" onClick={goToNext}></button>
      </div>
    </div>
  );
};

export default Carousel;
