import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import minedu from "assets/images/logo-lic-minedu.png";
import imagen1 from "assets/images/iconos/icon-empleabilidad.png";
import imagen2 from "assets/images/iconos/icon-empleabilidad.png";
import imagen3 from "assets/images/iconos/icon-modalidad.png";
import imagen4 from "assets/images/iconos/icon-infraestructura.png";
import imagen5 from "assets/images/iconos/icon-licenciado.png";
import imagen6 from "assets/images/iconos/icon-trabajo.png";

function Information() {
  const [boxes, setBoxes] = useState([
    {
      frontColor: "white",
      frontTextColor: "#000000",
      frontText: "Empleabilidad",
      frontTextSize: "20px",
      backColor: "rgba(0, 154, 166)",
      backTextColor: "#ffffff",
      backText: "Eventos y Talleres exclusivos para potenciar habilidades blandas.",
      backTextSize: "16px",
      image: imagen1,
      showImageOnFront: true,
      isHovered: false,
    },
    {
      frontColor: "white",
      frontTextColor: "#000000",
      frontText: "Infraestructura del primer nivel",
      frontTextSize: "18px",
      backColor: "rgba(0, 154, 166)",
      backTextColor: "#000000",
      backText: "Más de 20 salas prácticas y laboratorios totalmente equipados.",
      backTextSize: "14px",
      image: imagen2,
      showImageOnFront: true,
      isHovered: false,
    },
    {
      frontColor: "white",
      frontTextColor: "#000000",
      frontText: "Enfoque Internacional",
      frontTextSize: "18px",
      backColor: "rgba(0, 154, 166)",
      backTextColor: "#000000",
      backText: "Nuestras modalidades son presenciales y virtuales.",
      backTextSize: "14px",
      image: imagen3,
      showImageOnFront: true,
      isHovered: false,
    },
    {
      frontColor: "white",
      frontTextColor: "#000000",
      frontText: "Bolsa de empleo",
      frontTextSize: "18px",
      backColor: "rgba(0, 154, 166)",
      backTextColor: "#000000",
      backText: "Realizamos semimarios constantemente",
      backTextSize: "14px",
      image: imagen4,
      showImageOnFront: true,
      isHovered: false,
    },
    {
      frontColor: "white",
      frontTextColor: "#000000",
      frontText: "Instituto licenciado",
      frontTextSize: "18px",
      backColor: "rgba(0, 154, 166)",
      backTextColor: "#000000",
      backText: "Somos un instituto autolizado por el Ministerio de Educación",
      backTextSize: "14px",
      image: imagen5,
      showImageOnFront: true,
      isHovered: false,
    },
    {
      frontColor: "white",
      frontTextColor: "#000000",
      frontText: "#OrgulloWorldNet",
      frontTextSize: "18px",
      backColor: "rgba(0, 154, 166)",
      backTextColor: "#000000",
      backText: "Tenemos lo que necesitas para que seas dueño de tu futuro.",
      backTextSize: "14px",
      image: imagen6,
      showImageOnFront: true,
      isHovered: false,
    },
  ]);

  const handleMouseEnter = (index) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box, i) => (i === index ? { ...box, isHovered: true } : box))
    );
  };

  const handleMouseLeave = (index) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box, i) => (i === index ? { ...box, isHovered: false } : box))
    );
  };

  return (
    <MKBox component="section" my={-11} py={6} position="relative" overflow="hidden">
      {/* Fondo con esquina redonda */}
      <Box />
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 0, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h6" fontWeight="bolds">
            ¿Por qué elegir
          </MKTypography>
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <MKTypography variant="h2" fontWeight="bold" my={1}>
              World Net Institute?
            </MKTypography>
            {/* Agregar la barra de color verde debajo del texto */}
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px", // ajustar según el ancho deseado
                height: "10px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
          <MKTypography variant="h6" fontWeight="bolds" my={5}>
            Somos un instituto autorizado por el Ministerio de Educación. Tenemos lo que necesitas
            para que seas dueño de tu futuro.
          </MKTypography>
          {/* Agregar la imagen debajo del texto */}
          <img
            src={minedu}
            style={{
              width: "300px",
              height: "auto",
              marginTop: "20px",
            }}
            alt="Logo Minedu"
          />
          {/* Agregar los cuadros divididos en dos columnas */}
          <Grid container spacing={2} mt={5} justifyContent="center">
            {boxes.map((box, index) => (
              <Grid item xs={6} key={index}>
                <Box
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave(index)}
                  sx={{
                    width: "100%", // Ajustar el ancho de la caja
                    height: "230px",
                    borderRadius: "10px",
                    backgroundColor: box.isHovered ? box.backColor : box.frontColor,
                    color: box.isHovered ? box.backTextColor : box.frontTextColor,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "20px", // Ajustar el espacio interior
                    position: "relative", // Agregar posición relativa para posicionar la imagen
                  }}
                >
                  {/* Renderizar la imagen solo en la primera cara si showImageOnFront es true */}
                  {box.showImageOnFront && (
                    <img
                      src={box.image}
                      style={{
                        position: "absolute", // Ajustar posición absoluta para superponer la imagen sobre el texto
                        top: "50%", // Ajustar la posición verticalmente al centro
                        transform: "translateY(-50%)", // Ajustar para centrar verticalmente
                        width: "60px", // Ajustar el tamaño de la imagen
                        height: "auto",
                        zIndex: -1, // Asegurar que la imagen esté sobre el texto
                        marginBottom: "180px !important",
                      }}
                    />
                  )}
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{
                      fontSize: box.isHovered ? box.backTextSize : box.frontTextSize,
                      padding: "25px", // Ajustar el espaciado según sea necesario
                      position: "relative", // Añadir posición relativa para ajustar el texto sobre la imagen
                      marginTop: box.isHovered ? "0" : "150px", //
                      color: box.isHovered ? "white !important" : "black !important",
                    }}
                  >
                    {box.isHovered ? box.backText : box.frontText}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
      <Box mt={15} />
    </MKBox>
  );
}

export default Information;
