import React from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ExampleCard from "pages/Presentation/components/Example_notice";
import data from "pages/Presentation/sections/data/designBlocksData2";

function Section4() {
  const renderData = data.map(({ title, description, items }) => (
    <Grid container spacing={3} sx={{ mb: 5 }} textAlign="center" key={title}>
      <Grid item xs={12} lg={1.5}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          {items.map(({ image, name, count, route, pro, Tema, Subtema }) => (
            <Grid item xs={12} md={4} sx={{ mb: 2 }} key={name}>
              <Box
                sx={{
                  backgroundColor: "rgb(234, 245, 243)",
                  padding: "5px",
                  borderRadius: "10px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <ExampleCard image={image} count={count} pro={pro} />
                  <MKTypography variant="h3" fontWeight="bold" color="inherit" mt={5}>
                    {Tema}
                  </MKTypography>
                  <MKTypography variant="h6" fontWeight="bold" color="inherit" mt={5}>
                    {Subtema}
                  </MKTypography>
                </Box>
                <Box>
                  <Link to={pro ? "/" : route} style={{ textDecoration: "none" }}>
                    <Box
                      my={8}
                      sx={{
                        border: "1px solid rgb(0, 97, 200)", // Definir el borde con un grosor específico
                        borderRadius: "25px",
                        bottom: 20,
                        left: 25,
                        right: 60,
                        backgroundColor: "rgb(0, 97, 200) !important",
                        padding: "10px",
                        fontSize: "0.8rem",
                        "&:hover": {
                          backgroundColor: "rgb(0, 40, 85) !important",
                          color: "white !important",
                          borderColor: "rgb(0, 40, 85) !important",
                        },
                        "&:active": {
                          color: "white !important",
                        },
                      }}
                    >
                      <MKTypography
                        variant="h5"
                        fontWeight="bold"
                        color="white"
                        style={{
                          fontSize: "0.9rem",
                        }}
                      >
                        {name}
                      </MKTypography>
                    </Box>
                  </Link>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <MKBox component="section" py={5} position="relative" overflow="hidden">
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "250px",
          borderRadius: "180px 0 0 0",
          zIndex: -1,
        }}
      />
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 0, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h6" fontWeight="bolds">
            Elige una de nuestras
          </MKTypography>
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <MKTypography variant="h2" fontWeight="bold" my={1}>
              Modalidades de ingreso
            </MKTypography>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px",
                height: "10px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
          <MKTypography variant="h6" fontWeight="bolds" my={5}>
            Conviertete en un profesional de acción
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: 1 }}>{renderData}</Container>
      <Box mt={0} />
    </MKBox>
  );
}

export default Section4;
