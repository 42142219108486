// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import Instagram from "@mui/icons-material/Instagram";
import WhatsApp from "@mui/icons-material/WhatsApp";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/WorldNetCETPRO?mibextid=JRoKGi%7D",
    },
    {
      icon: <TwitterIcon />,
      link: "/presentation",
    },
    {
      icon: <Instagram />,
      link: "/presentation",
    },
    {
      icon: <WhatsApp />,
      link: "https://n9.cl/n5ffz",
    },
  ],
  menus: [
    {
      name: "",
      items: [
        { name: "Carreras", href: "/carreras-profesionales" },
        { name: "Educación continua", href: "/educacion-continua" },
      ],
    },
    {
      name: "",
      items: [
        { name: "Nosotros", href: "/nosotros" },
        { name: "¿Por qué WorldNet?", href: "/por-que-estudiar-en-world-net" },
      ],
    },
    {
      name: "",
      items: [
        { name: "Idiomas", href: "/idiomas" },
        { name: "¿Experiencia WorldNet?", href: "/noticias" },
      ],
    },
    {
      name: "",
      items: [
        { name: "Política de calidad", href: "/presentation" },
        { name: "Políticas de privacidad", href: "/presentation" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular" color="white" textAlign="center">
      INSTITUTO DE EDUCACIÓN SUPERIOR WORLD NET S.A.C {date}
      <br></br>CALLE MANUEL FUENTES 420<br></br>TODO LOS DERECHOS RESERVADOS
      <MKTypography
        component="a"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
        color="white"
        style={{ textAlign: "center" }}
      ></MKTypography>
      .
    </MKTypography>
  ),
};
