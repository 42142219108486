import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import imagen1 from "assets/images/iconos/icon-empleabilidad.png";
import imagen2 from "assets/images/iconos/icon-empleabilidad.png";
import imagen3 from "assets/images/iconos/icon-modalidad.png";
import imagen4 from "assets/images/iconos/icon-infraestructura.png";
import imagen5 from "assets/images/iconos/icon-licenciado.png";
import imagen6 from "assets/images/iconos/icon-trabajo.png";

function Section2() {
  const [boxes, setBoxes] = useState([
    {
      frontColor: "white",
      frontTextColor: "#000000",
      frontText: "Empleabilidad",
      frontTextSize: "20px",
      backColor: "rgba(0, 154, 166)",
      backTextColor: "#ffffff",
      backText: "Eventos y Talleres exclusivos para potenciar habilidades blandas.",
      backTextSize: "16px",
      image: imagen1,
      showImageOnFront: true,
      isHovered: false,
    },
    {
      frontColor: "white",
      frontTextColor: "#000000",
      frontText: "Infraestructura del primer nivel",
      frontTextSize: "18px",
      backColor: "rgba(0, 154, 166)",
      backTextColor: "#000000",
      backText: "Más de 20 salas prácticas y laboratorios totalmente equipados.",
      backTextSize: "14px",
      image: imagen2,
      showImageOnFront: true,
      isHovered: false,
    },
    {
      frontColor: "white",
      frontTextColor: "#000000",
      frontText: "Modalidades",
      frontTextSize: "18px",
      backColor: "rgba(0, 154, 166)",
      backTextColor: "#000000",
      backText: "Nuestras modalidades son presenciales y virtuales.",
      backTextSize: "14px",
      image: imagen3,
      showImageOnFront: true,
      isHovered: false,
    },
    {
      frontColor: "white",
      frontTextColor: "#000000",
      frontText: "Bolsa de trabajo",
      frontTextSize: "18px",
      backColor: "rgba(0, 154, 166)",
      backTextColor: "#000000",
      backText: "Prestigiosas empresas para que inicies tu vida profesional.",
      backTextSize: "14px",
      image: imagen4,
      showImageOnFront: true,
      isHovered: false,
    },
    {
      frontColor: "white",
      frontTextColor: "#000000",
      frontText: "Instituto licenciado",
      frontTextSize: "18px",
      backColor: "rgba(0, 154, 166)",
      backTextColor: "#000000",
      backText: "Somos un instituto autolizado por el Ministerio de Educación",
      backTextSize: "14px",
      image: imagen5,
      showImageOnFront: true,
      isHovered: false,
    },
    {
      frontColor: "white",
      frontTextColor: "#000000",
      frontText: "#OrgulloWorldNet",
      frontTextSize: "18px",
      backColor: "rgba(0, 154, 166)",
      backTextColor: "#000000",
      backText: "Tenemos lo que necesitas para que seas dueño de tu futuro.",
      backTextSize: "14px",
      image: imagen6,
      showImageOnFront: true,
      isHovered: false,
    },
  ]);

  const handleMouseEnter = (index) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box, i) => (i === index ? { ...box, isHovered: true } : box))
    );
  };

  const handleMouseLeave = (index) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box, i) => (i === index ? { ...box, isHovered: false } : box))
    );
  };

  return (
    <MKBox component="section" pt={20} my={-3}>
      <Container>
        <Grid container justifyContent="center" alignItems="center" flexDirection="column">
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            alignItems="center"
            sx={{ textAlign: "center", my: 0, mx: "auto", px: 0.75 }}
          >
            <MKTypography variant="h4" fontWeight="bolds">
              ¿Por qué
            </MKTypography>
            <Box sx={{ position: "relative", display: "inline-block" }}>
              <MKTypography variant="h1" fontWeight="bold" my={1}>
                Elegirnos?
              </MKTypography>
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  bottom: "-8px",
                  transform: "translateX(-50%)",
                  width: "50px",
                  height: "10px",
                  backgroundColor: "rgb(105, 190, 40)",
                  borderRadius: "12px",
                }}
              />
            </Box>
            <MKTypography variant="body2" fontWeight="bold" my={5}>
              El Instituto WorldNet transforma vidas desde hace másde 10 años a través de una
              experiencia educativa excepcional, formando expertos certificados que logren su
              autorrealización a través de nuestras 6 carreras profesionales, con un enfoque
              internacional y de alianzas que promuevan la continuidad académica y laformación
              laboral de todos nuestros estudiantes.
            </MKTypography>
          </Grid>
          <Grid container spacing={0} mt={5} justifyContent="center">
            {boxes.map((box, index) => (
              <Grid item xs={12} sm={6} md={4} lg={0} key={index}>
                <Box
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave(index)}
                  sx={{
                    width: "100%",
                    height: "15rem",
                    borderRadius: "10px",
                    backgroundColor: box.isHovered ? box.backColor : box.frontColor,
                    color: box.isHovered ? box.backTextColor : box.frontTextColor,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "20px",
                    position: "relative",
                    textAlign: "center",
                  }}
                >
                  {box.showImageOnFront && !box.isHovered && (
                    <img
                      src={box.image}
                      style={{
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        width: "5rem",
                        height: "auto",
                        zIndex: 1,
                        marginTop: box.isHovered ? "0" : "-40px",
                      }}
                      alt={box.frontText}
                    />
                  )}
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{
                      fontSize: box.isHovered ? box.backTextSize : box.frontTextSize,
                      padding: "25px",
                      position: "relative",
                      marginTop: box.isHovered ? "0" : "100px",
                      color: box.isHovered ? "white !important" : "black !important",
                    }}
                  >
                    {box.isHovered ? box.backText : box.frontText}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Section2;
