// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import data from "pages/Presentation/sections/data/designBlocksData";

function Section6() {
  const renderData = data.map(({ title, description, items }) => (
    <Grid container spacing={3} sx={{ mb: 5 }} textAlign="center" key={title}>
      <Grid item xs={12} lg={1.5}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          {items.map(({ image, name, count, route, pro }) => (
            <Grid item xs={12} md={4} sx={{ mb: 2 }} key={name}>
              <Link to={pro ? "/" : route}>
                <Box sx={{ position: "relative" }}>
                  <ExampleCard image={image} count={count} pro={pro} />
                  <Box
                    sx={{
                      position: "absolute",
                      border: "1px solid white", // Definir el borde con un grosor específico
                      borderRadius: "25px",
                      bottom: 20,
                      left: 25,
                      right: 60,
                      backgroundColor: "white !important",
                      padding: "10px",
                      "&:hover": {
                        backgroundColor: "rgba(0, 154, 166) !important",
                        color: "white !important",
                        borderColor: "rgb(0, 154, 166) !important",
                      },
                      "&:active": {
                        color: "rgb(0, 154, 166) !important",
                      },
                    }}
                  >
                    <MKTypography
                      variant="h5"
                      fontWeight="bold"
                      style={{
                        fontSize: "0.9rem",
                      }}
                    >
                      {name}
                    </MKTypography>
                  </Box>
                </Box>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <MKBox component="section" my={0} py={0}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 0, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h6" fontWeight="bolds">
            Profesionales en acción
          </MKTypography>
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <MKTypography variant="h2" fontWeight="bold" my={1}>
              Estudia en World Net Institute
            </MKTypography>
            {/* Agregar la barra de color verde debajo del texto */}
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px", // ajustar según el ancho deseado
                height: "10px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
      </Container>
      <Container sx={{ mt: 4 }}>{renderData}</Container>
    </MKBox>
  );
}

export default Section6;
