import React from "react";
import "libro-de-reclamaciones/styles/styles.css";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function PdfViewer() {
  return (
    <MKBox component="section" pt={6} my={-3}>
      <Container>
        <Grid
          container
          justifyContent="center" // Centrar horizontalmente
          alignItems="center" // Centrar verticalmente
          flexDirection="column" // Alinear los elementos en columna
        >
          <Box sx={{ position: "relative", display: "inline-block", textAlign: "center" }}>
            <MKTypography variant="h1" mb={2} mt={0}>
              En construcción
            </MKTypography>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px",
                height: "8px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
      </Container>
      <Box mt={5} />
    </MKBox>
  );
}

export default PdfViewer;
