import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import icon1 from "assets/images/iconos/icon-ingles.png";

function Section1() {
  return (
    <MKBox component="section" pt={6} my={-3}>
      <Container>
        <Grid
          container
          justifyContent="center" // Centrar horizontalmente
          alignItems="center" // Centrar verticalmente
          flexDirection="column" // Alinear los elementos en columna
        >
          <Box sx={{ position: "relative", display: "inline-block", textAlign: "center" }}>
            <MKTypography variant="h1" mb={2} mt={0}>
              Ingles
            </MKTypography>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px",
                height: "8px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
        <Grid container my={4} justifyContent="center">
          <Grid item xs={12} md={8.8} sx={{ ml: { xs: 0, lg: 0 }, mb: { xs: 3, md: 0 } }}>
            <MKTypography variant="body2" color="black" mb={4} my={5} textAlign="center">
              En WorldNet Institute, nuestro objetivo es preparar a nuestros estudiantes para el
              éxito en un mundo globalizado, brindándoles las habilidades lingüísticas y el
              conocimiento necesario para alcanzar sus metas y aspiraciones personales y
              profesionales. Estamos comprometidos a ser líderes en la enseñanza del inglés y a
              proporcionar una educación de calidad que marque la diferencia en la vida de nuestros
              estudiantes.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
      <Grid container my={-5} spacing={5} justifyContent="center">
        <Grid item xs={10} sm={6} md={3} lg={2}>
          <Box
            sx={{
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "1rem",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)",
            }}
          >
            <img
              src={icon1}
              style={{
                maxWidth: "100%",
                height: "auto",
                maxHeight: "4rem",
                marginBottom: "1.8rem",
              }}
            />
            <MKTypography variant="h6" color="black" mb={0.5} textAlign="center" fontSize="0.8rem">
              Ingles Básico I
            </MKTypography>
            <MKTypography variant="h6" color="black" mb={0.5} textAlign="center" fontSize="0.8rem">
              Ingles Básico II
            </MKTypography>
          </Box>
        </Grid>
        <Grid item xs={10} sm={6} md={3} lg={2}>
          <Box
            sx={{
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "1rem",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)",
            }}
          >
            <img
              src={icon1}
              style={{
                maxWidth: "100%",
                height: "auto",
                maxHeight: "4rem",
                marginBottom: "1.9rem",
              }}
            />
            <MKTypography variant="h6" color="black" mb={0.5} textAlign="center" fontSize="0.8rem">
              Ingles Avanzado I I
            </MKTypography>
            <MKTypography variant="h6" color="black" mb={0.5} textAlign="center" fontSize="0.8rem">
              Ingles avanzado, conversacion en Ingles
            </MKTypography>
          </Box>
        </Grid>
        <Grid item xs={10} sm={6} md={3} lg={2}>
          <Box
            sx={{
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "1rem",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)",
            }}
          >
            <img
              src={icon1}
              style={{
                maxWidth: "100%",
                height: "auto",
                maxHeight: "4rem",
                marginBottom: "1.9rem",
              }}
            />
            <MKTypography variant="h6" color="black" mb={0.5} textAlign="center" fontSize="0.8rem">
              Técnicas de redacción en Ingles
            </MKTypography>
          </Box>
        </Grid>
      </Grid>
      <Box mt={0} />
    </MKBox>
  );
}

export default Section1;
