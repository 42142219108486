import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Container, Card, Fab } from "@mui/material";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";
//imagenes
import image1 from "assets/images/imagenes-de-portada/carrusel-1.png";
import image2 from "assets/images/imagenes-de-portada/carrusel-2.png";
import image3 from "assets/images/imagenes-de-portada/carrusel-3.png";

import whatsapp from "assets/images/whatsapp.png";
import NextArrow from "pages/Presentation/components/NextCarrusel/NextArrow";
import PrevArrow from "pages/Presentation/components/PrevCarrusel/PrevArrow";
import Section1 from "pages/Presentation/sections/section1";
import Section2 from "pages/Presentation/sections/section2";
import Section3 from "pages/Presentation/sections/section3";
import Section4 from "pages/Presentation/sections/section4";
import Section5 from "pages/Presentation/sections/section5";
import Section6 from "pages/Presentation/sections/section6";

function Presentation() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <ul style={{ margin: "0px", padding: "0px", listStyle: "none" }}> {dots} </ul>
      </div>
    ),
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://n9.cl/n5ffz",
          label: "INSCRIBITE",
          color: "info",
        }}
        sticky
      />
      <Slider {...settings} style={{ marginBottom: "100px" }}>
        <div>
          <Link to="/carreras/computacion">
            <img
              src={image1}
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "2000px",
                borderBottomLeftRadius: "150px",
                pointerEvents: "none",
                backgroundColor: "rgb(0, 154, 166)",
              }}
            />
          </Link>
        </div>
        <div>
          <Link to="/carreras-profesionales">
            <img
              src={image2}
              style={{
                width: "100%",
                borderBottomLeftRadius: "150px",
                pointerEvents: "none",
                backgroundColor: "rgb(0, 154, 166)",
              }}
            />
          </Link>
        </div>
        <div>
          <Link to="https://n9.cl/n5ffz">
            <img
              src={image3}
              style={{
                width: "100%",
                borderBottomLeftRadius: "150px",
                pointerEvents: "none",
                backgroundColor: "rgb(0, 154, 166)",
              }}
            />
          </Link>
        </div>
      </Slider>
      <Container>
        <Card
          sx={{
            p: 2,
            mt: -13.6,
            mb: 4,
            width: "100%",
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Section1 />
          <Section2 />
          <Section3 />
          <Section4 />
          <Section5 />
          <Section6 />
        </Card>
      </Container>
      <DefaultFooter content={footerRoutes} />

      <Link
        to="https://n9.cl/n5ffz"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
        }}
      >
        <Fab
          color="primary"
          aria-label="add"
          style={{
            width: "56px",
            height: "56px",
          }}
        >
          {/* Imagen dentro del botón */}
          <img
            src={whatsapp}
            alt="WhatsApp"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "50px",
            }}
          />
        </Fab>
      </Link>
    </>
  );
}

export default Presentation;
