// PrevArrow.js
import React from "react";
import PropTypes from "prop-types";

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        position: "absolute",
        top: "50%",
        left: "calc(5% - 20px)",
        width: "20px",
        zIndex: 1,
        transform: "translateY(-50%)",
        cursor: "pointer",
        color: "white",
      }}
      onClick={onClick}
    />
  );
};

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

export default PrevArrow;
