import React, { useState } from "react";
import "libro-de-reclamaciones/styles/styles.css";
import SeleccionUbicacion from "libro-de-reclamaciones/sections/lugares";

function Formulario() {
  const initialState = {
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    dniPasaporte: "",
    telefono: "",
    email: "",
    emailAlternativo: "",
    mayorEdad: false,
    otroCampo: "",
    ubicacion: {
      departamento: "",
      provincia: "",
      distrito: "",
    },
    direccion: "",
    opcionSeccion2: "",
    opcionSeccion3: "",
    textoSeccion5: "",
    textoSeccion6: "",
    archivo: null,
    textoSeccion8: "",
    checkPolitica: false,
    checkAutorizacion: false,
  };

  const [formData, setFormData] = useState(initialState);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleUbicacionChange = (ubicacion) => {
    setFormData({
      ...formData,
      ubicacion: ubicacion,
    });
  };

  const handleSubmitSeccion1 = (e) => {
    e.preventDefault();
    console.log("Sección 1:", formData);
    // Aquí puedes manejar el envío del formulario de la sección 1, por ejemplo, enviarlo a un servidor.
  };

  const handleSubmitSeccion2 = (e) => {
    e.preventDefault();
    console.log("Sección 2:", formData.opcionSeccion2);
    // Aquí puedes manejar el envío del formulario de la sección 2, por ejemplo, enviarlo a un servidor.
  };

  const handleSubmitSeccion3 = (e) => {
    e.preventDefault();
    console.log("Sección 3:", formData.opcionSeccion3);
    // Aquí puedes manejar el envío del formulario de la sección 3, por ejemplo, enviarlo a un servidor.
  };

  const handleSubmitSeccion5 = (e) => {
    e.preventDefault();
    console.log("Sección 5:", formData.textoSeccion5);
    // Aquí puedes manejar el envío del formulario de la sección 5, por ejemplo, enviarlo a un servidor.
  };

  const handleSubmitSeccion6 = (e) => {
    e.preventDefault();
    console.log("Sección 6:", formData.textoSeccion6);
    // Aquí puedes manejar el envío del formulario de la sección 6, por ejemplo, enviarlo a un servidor.
  };

  const handleSubmitSeccion7 = (e) => {
    e.preventDefault();
    console.log("Sección 7 - Archivo:", formData.archivo);
    // Aquí puedes manejar la carga del archivo, por ejemplo, enviarlo a un servidor.
  };

  const handleSubmitSeccion8 = (e) => {
    e.preventDefault();
    console.log("Sección 8 ", formData.textoSeccion8);
    // Aquí puedes manejar la carga del archivo, por ejemplo, enviarlo a un servidor.
  };

  const handleLimpiarTodo = () => {
    setFormData(initialState);
  };

  return (
    <div className="form-container">
      <div>
        <h3>1. Identificación del consumidor reclamante</h3>
        <form onSubmit={handleSubmitSeccion1} className="formulario-grid">
          <div className="grid-item">
            <input
              type="text"
              name="nombres"
              value={formData.nombres}
              onChange={handleChange}
              placeholder="Nombres completos"
              className="input-field"
            />
          </div>
          <div className="grid-item">
            <input
              type="text"
              name="apellidoPaterno"
              value={formData.apellidoPaterno}
              onChange={handleChange}
              placeholder="Apellido paterno"
              className="input-field"
            />
          </div>
          <div className="grid-item">
            <input
              type="text"
              name="apellidoMaterno"
              value={formData.apellidoMaterno}
              onChange={handleChange}
              placeholder="Apellido materno"
              className="input-field"
            />
          </div>
          <div className="grid-item">
            <input
              type="text"
              name="dniPasaporte"
              value={formData.dniPasaporte}
              onChange={handleChange}
              placeholder="DNI/PASAPORTE"
              className="input-field"
            />
          </div>
          <div className="grid-item">
            <input
              type="tel"
              name="telefono"
              value={formData.telefono}
              onChange={handleChange}
              placeholder="Teléfono"
              className="input-field"
            />
          </div>
          <div className="grid-item">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className="input-field"
            />
          </div>
          <div className="grid-item">
            <input
              type="email"
              name="emailAlternativo"
              value={formData.emailAlternativo}
              onChange={handleChange}
              placeholder="Email Alternativo"
              className="input-field"
            />
          </div>
          <div className="grid-item">
            <input
              type="checkbox"
              name="mayorEdad"
              checked={formData.mayorEdad}
              onChange={handleChange}
            />
            <label htmlFor="mayorEdad">Soy mayor de edad</label>
          </div>
          {formData.mayorEdad ? null : (
            <div className="grid-item">
              <input
                type="text"
                name="otroCampo"
                value={formData.otroCampo}
                onChange={handleChange}
                placeholder="Campo adicional"
                className="input-field"
              />
            </div>
          )}
          <div className="direccion-container">
            <SeleccionUbicacion onChange={handleUbicacionChange} />
          </div>
          <div className="direccion-container">
            <input
              type="text"
              name="direccion"
              value={formData.direccion}
              onChange={handleChange}
              placeholder="Ingrese su dirección"
              className="direccion-input"
            />
          </div>
        </form>
      </div>
      <div>
        <h3>2. Identificación del bien contratante</h3>
        <form onSubmit={handleSubmitSeccion2} className="formulario-grid-2">
          <div className="grid-item-2">
            <label>
              <input
                type="radio"
                name="opcionSeccion2"
                value="opcion1"
                checked={formData.opcionSeccion2 === "opcion1"}
                onChange={handleChange}
              />
              Servicio
            </label>
            <input
              type="text"
              name="textoOpcion1"
              value={formData.textoOpcion1}
              onChange={handleChange}
              placeholder="Monto reclamado"
              className="input-field-2"
            />
          </div>
          <div className="grid-item">
            <label>
              <input
                type="radio"
                name="opcionSeccion2"
                value="opcion2"
                checked={formData.opcionSeccion2 === "opcion2"}
                onChange={handleChange}
              />
              Producto
            </label>
            <input
              type="text"
              name="textoOpcion2"
              value="Servicios educativos"
              disabled
              className="input-field-2"
            />
          </div>
        </form>
      </div>
      <div>
        <h3>3. Tipo</h3>
        <form onSubmit={handleSubmitSeccion3} className="formulario-grid-2">
          <div className="grid-item">
            <label>
              <input
                type="radio"
                name="opcionSeccion3"
                value="opcion1"
                checked={formData.opcionSeccion3 === "opcion1"}
                onChange={handleChange}
              />
              Reclamo
            </label>
          </div>
          <div className="grid-item">
            <label>
              <input
                type="radio"
                name="opcionSeccion3"
                value="opcion2"
                checked={formData.opcionSeccion3 === "opcion2"}
                onChange={handleChange}
              />
              Queja
            </label>
          </div>
        </form>
      </div>
      <div>
        <h3>4. Sede / Carrera o Programa / Área</h3>
        <form onSubmit={handleSubmitSeccion3} className="formulario-grid-2">
          <div className="grid-item">
            <select
              name="sede"
              value={formData.sede}
              onChange={handleChange}
              className="input-field-2"
            >
              <option value="">Seleccione la sede</option>
              <option value="sede1">Central</option>
              <option value="sede2">San Isidro</option>
              <option value="sede3">Lince</option>
              <option value="sede3">Ayacucho</option>
            </select>
          </div>
          <div className="grid-item">
            <select
              name="carreraPrograma"
              value={formData.carreraPrograma}
              onChange={handleChange}
              className="input-field-2"
            >
              <option value="">Carrera / Programa</option>
              <option value="carrera1">Administración bancaria</option>
              <option value="carrera2">Administración y Marketing</option>
              <option value="carrera3">Asistente Administrativo Bilingue</option>
              <option value="carrera3">Computación</option>
              <option value="carrera3">Gastronomía</option>
              <option value="carrera3">Secretariado Bilingue</option>
            </select>
          </div>
        </form>
      </div>
      <div>
        <h3>5. Detalle de la relamación</h3>
        <form onSubmit={handleSubmitSeccion5} className="formulario-grid-3">
          <div className="grid-item">
            <input
              type="text"
              name="textoSeccion5"
              value={formData.textoSeccion5}
              onChange={handleChange}
              placeholder="Descripción*"
              className="section-input"
            />
          </div>
        </form>
      </div>
      <div>
        <h3>6. Pedido del consumidor</h3>
        <form onSubmit={handleSubmitSeccion6} className="formulario-grid-3">
          <div className="grid-item">
            <input
              type="text"
              name="textoSeccion6"
              value={formData.textoSeccion6}
              onChange={handleChange}
              placeholder="Descripción*"
              className="section-input"
            />
          </div>
        </form>
      </div>
      <div>
        <h3>7. Subir archivo</h3>
        <form onSubmit={handleSubmitSeccion7} className="formulario-grid-4">
          <div className="grid-item">
            <input type="file" name="archivo" onChange={handleChange} className="section-input-4" />
          </div>
        </form>
      </div>
      <div>
        <h3>8. Observaciones y acciones</h3>
        <form onSubmit={handleSubmitSeccion8} className="formulario-grid-3">
          <div className="grid-item">
            <input
              type="text"
              name="textoSeccion7"
              value={formData.textoSeccion8}
              onChange={handleChange}
              placeholder="Descripción*"
              className="section-input"
            />
          </div>
        </form>
      </div>
      <h3></h3>
      <div className="direccion-container">
        <form className="grid-container">
          <div className="grid-item">
            <input
              type="checkbox"
              name="checkPolitica"
              checked={formData.checkPolitica}
              onChange={handleChange}
            />
          </div>
          <div className="grid-item">
            <h6>
              He leído la Política de Privacidad del Instituto WorldNet y doy mi consentimiento para
              el tratamiento de los datos personales consignados aquí.
            </h6>
          </div>
        </form>
      </div>

      <div className="direccion-container">
        <form className="grid-container">
          <div className="grid-item">
            <input
              type="checkbox"
              name="checkAutorizacion"
              checked={formData.checkAutorizacion}
              onChange={handleChange}
            />
          </div>
          <div className="grid-item">
            <h6>
              Autorizo al Instituto WorldNet contactarme y enviarme información de los servicios
              educativos que ofrece y publicidad, en general.
            </h6>
          </div>
        </form>
        <h3></h3>
        <button onClick={handleLimpiarTodo} className="boton-limpiar">
          Limpiar
        </button>
        <button type="submit" className="boton-enviar">
          Enviar
        </button>
      </div>
    </div>
  );
}

export default Formulario;
