import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Box from "@mui/material/Box";

// Images
import perfil from "assets/images/cards/card-perfile.png";

function Section4() {
  return (
    <MKBox component="section" pt={6} my={8}>
      <Container>
        <Grid
          container
          justifyContent="center" // Centrar horizontalmente
          alignItems="center" // Centrar verticalmente
          flexDirection="column" // Alinear los elementos en columna
        >
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <MKTypography variant="h2" mb={2} mt={0}>
              Finalidad
            </MKTypography>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px",
                height: "8px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
        <Grid
          container
          alignItems="flex-start"
          my={13}
          x={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <Grid item xs={12} my={-11} md={5} sx={{ ml: { xs: 0, lg: 25 }, mb: { xs: 3, md: 0 } }}>
            <MKTypography variant="body2" color="black" mb={4} my={12}>
              Nuestra finalidad en la enseñanza del inglés en el WorldNet Institute es proporcionar
              a nuestros estudiantes las habilidades lingüísticas, comunicativas, culturales y
              profesionales necesarias para tener éxito en un mundo cada vez más interconectado y
              diverso. Nos esforzamos por ofrecerles una experiencia educativa enriquecedora que les
              permita alcanzar sus metas y aspiraciones personales y profesionales.
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ ml: "85px", mb: { xs: 4, md: 0 } }}>
            <MKBox mb={4} my={-6}>
              <MKBox
                component="img"
                src={perfil}
                alt="macbook"
                width="15rem"
                sx={{
                  borderRadius: "12px",
                }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Section4;
