//imagenes
import bgImage1 from "assets/images/card_carrera.png";
import bgImage2 from "assets/images/card-idiomas.png";
import bgImage3 from "assets/images/card-cursos.png";

export default [
  {
    items: [
      {
        image: `${bgImage1}`,
        name: "Más información",
        route: "/carreras-profesionales",
      },
      {
        image: `${bgImage2}`,
        name: "Más información",
        route: "/idiomas",
      },
      {
        image: `${bgImage3}`,
        name: "Más información",
        route: "/educacion-continua",
      },
    ],
  },
];
