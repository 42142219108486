import React from "react";
import MKTypography from "components/MKTypography";
import "transparencia/ingresantes-matriculados-y-egresados/styles/styles.css";

function Section2() {
  // Define los textos para cada celda de la tabla
  const tableTexts = [
    ["INGRESANTES", "", "", ""],
    ["", "AÑO", "", ""],
    ["ESPECIALIDAD", "2021", "2022", "2023"],
    ["Admnistración Bancaria", "0", "0", "0"],
    ["Administración y Marketing", "0", "0", "0"],
    ["Asistente Administrativo bilingue", "0", "0", "0"],
    ["Computación", "0", "0", "0"],
    ["Gatronomía", "0", "0", "0"],
    ["Secretariado Bilingue", "0", "0", "0"],
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
      }}
    >
      <table style={{ borderCollapse: "separate", borderSpacing: "0px" }}>
        <tbody>
          {tableTexts.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              style={{ backgroundColor: rowIndex === 0 ? "#ccc" : "inherit", margin: "15px 0" }}
            >
              {row.map((text, colIndex) => (
                <td key={colIndex} style={{ width: colIndex === 0 ? "600px" : "100px" }}>
                  <MKTypography variant="h6"> {text} </MKTypography>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Section2;
