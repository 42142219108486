import React, { useState } from "react";
import "libro-de-reclamaciones/styles/styles.css";

// Datos de ejemplo
const departamentos = [
  {
    nombre: "Amazonas",
    provincias: [
      {
        nombre: "Chachapoyas",
        distritos: ["Chachapoyas", "Asunción", "Balsas"],
      },
      {
        nombre: "Huaral",
        distritos: ["Huaral", "Aucallama", "Chancay"],
      },
    ],
  },
  {
    nombre: "Lima",
    provincias: [
      {
        nombre: "Lima",
        distritos: ["Miraflores", "San Isidro", "Surco"],
      },
      {
        nombre: "Huaral",
        distritos: ["Huaral", "Aucallama", "Chancay"],
      },
    ],
  },
  {
    nombre: "Arequipa",
    provincias: [
      {
        nombre: "Arequipa",
        distritos: ["Arequipa", "Cayma", "Yanahuara"],
      },
      {
        nombre: "Camaná",
        distritos: ["Camaná", "José María Quimper", "Mariano Nicolás Valcárcel"],
      },
    ],
  },
  // Agregar más departamentos según sea necesario
];

function SeleccionUbicacion() {
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [selectedProvincia, setSelectedProvincia] = useState("");
  const [selectedDistrito, setSelectedDistrito] = useState("");
  const [provinciasDisabled, setProvinciasDisabled] = useState(true);
  const [distritosDisabled, setDistritosDisabled] = useState(true);

  const handleDepartamentoChange = (e) => {
    const departamentoSeleccionado = e.target.value;
    setSelectedDepartamento(departamentoSeleccionado);
    setSelectedProvincia("");
    setSelectedDistrito("");
    setProvinciasDisabled(departamentoSeleccionado === "");
    setDistritosDisabled(true);
  };

  const handleProvinciaChange = (e) => {
    const provinciaSeleccionada = e.target.value;
    setSelectedProvincia(provinciaSeleccionada);
    setSelectedDistrito("");
    setDistritosDisabled(provinciaSeleccionada === "");
  };

  const handleDistritoChange = (e) => {
    setSelectedDistrito(e.target.value);
  };

  return (
    <div className="form-container">
      <form className="formulario-grid">
        <div className="grid-item">
          <select value={selectedDepartamento} onChange={handleDepartamentoChange}>
            <option value="">Seleccione un departamento</option>
            {departamentos.map((departamento, index) => (
              <option key={index} value={departamento.nombre}>
                {departamento.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="grid-item">
          <select
            value={selectedProvincia}
            onChange={handleProvinciaChange}
            disabled={provinciasDisabled}
          >
            <option value="">Seleccione una provincia</option>
            {selectedDepartamento &&
              departamentos
                .find((departamento) => departamento.nombre === selectedDepartamento)
                .provincias.map((provincia, index) => (
                  <option key={index} value={provincia.nombre}>
                    {provincia.nombre}
                  </option>
                ))}
          </select>
        </div>
        <div className="grid-item">
          <select
            value={selectedDistrito}
            onChange={handleDistritoChange}
            disabled={distritosDisabled}
          >
            <option value="">Seleccione un distrito</option>
            {selectedDepartamento &&
              selectedProvincia &&
              departamentos
                .find((departamento) => departamento.nombre === selectedDepartamento)
                .provincias.find((provincia) => provincia.nombre === selectedProvincia)
                .distritos.map((distrito, index) => (
                  <option key={index} value={distrito}>
                    {distrito}
                  </option>
                ))}
          </select>
        </div>
      </form>
    </div>
  );
}

export default SeleccionUbicacion;
