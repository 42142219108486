import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import icon1 from "assets/images/iconos/icon-objetivo.png";

function Section3() {
  const texts = [
    "Practicar habilidades culinarias.",
    "Seguir instrucciones y trabajar en equipo.",
    "Desarrollar habilidades de gestión y liderazgo.",
    "Adquirir conocimientos sobre seguridad alimentaria y normativas.",
    "Colaborar en proyectos y eventos.",
  ];

  return (
    <MKBox component="section" pt={6} my={-3}>
      <Container>
        <Grid
          container
          justifyContent="center" // Centrar horizontalmente
          alignItems="center" // Centrar verticalmente
          flexDirection="column" // Alinear los elementos en columna
        >
          <Box sx={{ position: "relative", display: "inline-block", textAlign: "center" }}>
            <MKTypography variant="h2" mb={2} mt={0}>
              Conoce tus funciones
            </MKTypography>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px",
                height: "8px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
      </Container>
      <Container sx={{ margin: "0 auto", padding: "5rem" }}>
        <Grid
          container
          spacing={4} // Espacio entre las tarjetas
          justifyContent="center" // Centrar horizontalmente las columnas
        >
          {texts.map((text, index) => (
            <Grid item key={index}>
              <Box
                sx={{
                  backgroundColor: "rgba(129, 243, 227)",
                  padding: "1rem",
                  height: "9.3rem",
                  width: "25rem",
                  borderRadius: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.9)", // Sombra negra
                }}
              >
                <img
                  src={icon1}
                  alt={`Icon ${index + 1}`}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    maxHeight: "2rem",
                    marginBottom: "0.5rem",
                  }}
                />
                <MKTypography variant="h6" color="black" mb={1} textAlign="center" fontSize="1rem">
                  {text}
                </MKTypography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Box mt={0} />
    </MKBox>
  );
}

export default Section3;
