import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Section1() {
  return (
    <MKBox component="section" pt={6} my={5}>
      <Container>
        <Grid
          container
          justifyContent="center" // Centrar horizontalmente
          alignItems="center" // Centrar verticalmente
          flexDirection="column" // Alinear los elementos en columna
        >
          <Box sx={{ position: "relative", display: "inline-block", textAlign: "center" }}>
            <MKTypography variant="h2" mb={2} mt={0}>
              Nuestros beneficios
            </MKTypography>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px",
                height: "8px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8.8} sx={{ ml: { xs: 0, lg: 0 } }}>
            <MKTypography variant="body2" color="black" mb={4} my={5} textAlign="center">
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tbody>
                  <tr style={{ backgroundColor: "rgb(11, 15, 91)", color: "white" }}>
                    <td style={{ padding: "10px", textAlign: "center" }}>Algunos beneficios</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        backgroundColor: "rgb(228, 240, 238)",
                      }}
                    >
                      Precios más bajos / durante la carrera.
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "10px", textAlign: "center" }}>
                      Estudio 2x1 carrera técnica profesional y una especialización, con cursos
                      técnicos adicional para completar tu carrera (no paga la especialización ni la
                      otra opción técnica dicional).
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        backgroundColor: "rgb(228, 240, 238)",
                      }}
                    >
                      Obtener certificados gratis durante tu carrera para que implementes en tu CV.
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "10px", textAlign: "center" }}>
                      Practicas Pre Profesionales con certificación laboral.
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        backgroundColor: "rgb(228, 240, 238)",
                      }}
                    >
                      Ceremonia de graduación virtual gratuito.
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "10px", textAlign: "center" }}>
                      100% Virtual toda la carrera.
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        backgroundColor: "rgb(228, 240, 238)",
                      }}
                    >
                      Graduación en Sede Lima
                    </td>
                  </tr>
                </tbody>
              </table>
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
      <Box mt={0} />
    </MKBox>
  );
}

export default Section1;
