import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MKTypography from "components/MKTypography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function Section9() {
  const [expanded, setExpanded] = useState([false, false, false]);

  const handleExpand = (index) => {
    setExpanded((prevExpanded) => prevExpanded.map((exp, i) => (i === index ? !exp : exp)));
  };

  // Arrays de textos para cajas principales y texto expandido para cada caja
  const mainTexts = ["Admisión - 17 de Marzo", "Matricula", "Pensiones"];

  const expandedTexts = [
    "* El costo es de 50 soles (Incluye constancia de ingreso).",
    "* El costo es de 100 soles (Incluye ficha de matrícula).",
    "* En total son 5 pensiones por ciclo modular – 100 soles.",
  ];

  return (
    <Container maxWidth="md" style={{ paddingTop: "6rem" }}>
      <Container>
        <Grid
          container
          justifyContent="center" // Centrar horizontalmente
          alignItems="center" // Centrar verticalmente
          flexDirection="column" // Alinear los elementos en columna
        >
          <Box sx={{ position: "relative", display: "inline-block", textAlign: "center" }}>
            <MKTypography variant="h2" mb={2} mt={0}>
              Costos académicos
            </MKTypography>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px",
                height: "8px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
      </Container>
      <Grid container spacing={0} justifyContent="center" my={5}>
        {[0, 1, 2].map((index) => (
          <Grid item xs={12} key={index}>
            <Box
              sx={{
                backgroundColor: index % 2 === 0 ? "#f0f0f0" : "#e0e0e0",
                padding: "20px",
                position: "relative",
                maxWidth: "600px", // Ajusta el ancho máximo de las cajas
                margin: "0 auto", // Centra horizontalmente las cajas
                marginBottom: "20px", // Espaciado entre cajas
              }}
            >
              <Box sx={{ position: "relative" }}>
                <MKTypography variant="h5" color="black" mb={4} my={5} textAlign="center">
                  {mainTexts[index]}
                </MKTypography>
                <Button
                  onClick={() => handleExpand(index)}
                  sx={{
                    position: "absolute",
                    top: "-0.5rem",
                    right: "1rem",
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                  }}
                  disableRipple
                >
                  <Box
                    sx={{
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    {expanded[index] ? <RemoveIcon /> : <AddIcon />}
                  </Box>
                </Button>
              </Box>
              {expanded[index] && (
                <MKTypography variant="h6" color="black" mt={2} mb={5}>
                  {expandedTexts[index]}
                </MKTypography>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Section9;
