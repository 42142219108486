import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Box from "@mui/material/Box";

function Section7() {
  return (
    <MKBox component="section" pt={6} my={10}>
      <Container>
        <Grid
          container
          justifyContent="center" // Centrar horizontalmente
          alignItems="center" // Centrar verticalmente
          flexDirection="column" // Alinear los elementos en columna
        >
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <MKTypography variant="h2" mb={2} mt={0} textAlign="center">
              Culminado la carrera obtendrás
            </MKTypography>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px",
                height: "8px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
        <Grid
          container
          justifyContent="center" // Centrar horizontalmente
          alignItems="center" // Centrar verticalmente
          flexDirection="column" // Alinear los elementos en columna
        >
          <Grid item xs={12} my={0} md={4.5} sx={{ ml: { xs: 0, lg: 0 }, mb: { xs: 3, md: 0 } }}>
            <MKTypography variant="h4" mb={2} mt={9} textAlign="center">
              CERTIFICIACIONES:
            </MKTypography>
            <MKTypography variant="body2" color="black" mb={4} my={0} textAlign="center">
              <ul>
                <li> Entrega de boleta de notas. </li>
                <li> Entrega de certificado modular emitido por el Minedu. </li>
                <li> Certificación adicional (300hrs pedagógicas). </li>
                <li> Certificación técnicos (600hrs pedagógicas). </li>
                <li> Entrega de especialización (1200hrs pedagógicas). </li>
                <li> Se entrega boleta de SUNAT RUC: 20611670274. </li>
                <li> Entrega de boleta de notas. </li>
              </ul>
            </MKTypography>
          </Grid>
          <Grid item xs={12} my={0} md={4.5} sx={{ ml: { xs: 0, lg: 0 }, mb: { xs: 3, md: 0 } }}>
            <MKTypography variant="h4" mb={2} mt={9} textAlign="center">
              TITULO:
            </MKTypography>
            <MKTypography variant="body2" color="black" mb={4} my={0} textAlign="center">
              <li> Profesional Tecnico en Computación. </li>
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
      <Box mt={7} />
    </MKBox>
  );
}

export default Section7;
