import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Divider from "@mui/material/Divider";

// Importa tu PDF
import miPDF from "./PLAN COMPUTACIÓN.pdf";

// Función para manejar la descarga del PDF
function handleDownload() {
  // Crea un enlace temporal para descargar el PDF
  const link = document.createElement("a");
  link.href = miPDF;
  link.setAttribute("download", "Plan de estudios");
  document.body.appendChild(link);
  link.click();
  // Elimina el enlace después de la descarga
  document.body.removeChild(link);
}

function Section5() {
  const [buttonPressed, setButtonPressed] = useState(false);

  return (
    <MKBox
      component="section"
      pt={0}
      my={0}
      ml={0}
      sx={{
        background: "#004165", // Color de fondo verde
        borderRadius: "20px", // Radio de las esquinas
      }}
    >
      <MKBox component="section" pt={5} my={1} ml={15}>
        <Container>
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <MKTypography variant="h2" mb={2} mt={0} color="white">
              ¿Por qué estudiar en WorldNet?
            </MKTypography>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                bottom: "-8px",
                transform: "translateX(-50%)",
                width: "50px",
                height: "8px",
                backgroundColor: "rgb(105, 190, 40)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Container>
        <Container sx={{ margin: "0 auto", padding: "1rem", position: "relative" }}>
          <Box sx={{ position: "absolute", top: "100%", right: "50%", zIndex: "9999" }}>
            <button
              onClick={handleDownload}
              onMouseDown={() => setButtonPressed(true)}
              onMouseUp={() => setButtonPressed(false)}
              onMouseLeave={() => setButtonPressed(false)}
              style={{
                padding: "15px",
                backgroundColor: buttonPressed ? "black" : "#004165",
                color: buttonPressed ? "blue" : "white",
                border: "2px solid white",
                borderRadius: "5px",
                cursor: "pointer",
                fontFamily: "Roboto",
                transition: "background-color 0.3s, color 0.3s, border-color 0.3s",
                "&:hover": {
                  backgroundColor: "black",
                },
              }}
            >
              Descargar Plan de estudio
            </button>
          </Box>
          <MKTypography variant="h4" mb={0} mt={2} color="white">
            Periodo Académico
          </MKTypography>
          <Grid container spacing={2} sx={{ mt: 5, mx: { xs: 3, md: 0 } }}>
            <Grid item xs={12} md={2.8}>
              <MKTypography variant="h4" color="white">
                Ciclo
              </MKTypography>
              <MKTypography variant="h1" color="white" sx={{ mt: 1 }}>
                01
              </MKTypography>
              <Divider sx={{ mt: 2, mb: 2, borderColor: "#4caf50" }} />
              <MKTypography variant="body2" color="white">
                <Box sx={{ fontSize: "0.8rem", maxWidth: "200px" }}>
                  <ul>
                    <li> OFIMATICA BÁSICA </li>
                    <li> DISEÑO GRÁFICO PUBLICITARIO </li>
                    <li> DISEÑO WEB I </li>
                    <li> HERRAMIENTAS DE INTERNET </li>
                  </ul>
                </Box>
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={2.6}>
              <MKTypography variant="h4" color="white">
                Ciclo
              </MKTypography>
              <MKTypography variant="h1" color="white" sx={{ mt: 1 }}>
                02
              </MKTypography>
              <Divider sx={{ mt: 2, mb: 2, borderColor: "#4caf50" }} />
              <MKTypography variant="body2" color="white">
                <Box sx={{ fontSize: "0.8rem", maxWidth: "200px" }}>
                  <ul>
                    <li> OFIMATICA AVANZADA </li>
                    <li> DISEÑO GRÁFICO AVANZADO </li>
                    <li> PÁGINAS WEB Y PROGRAMACIÁN BASICO </li>
                    <li> REDES SOCIALES Y MARKETING </li>
                  </ul>
                </Box>
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={2.6}>
              <MKTypography variant="h4" color="white">
                Ciclo
              </MKTypography>
              <MKTypography variant="h1" color="white" sx={{ mt: 1 }}>
                03
              </MKTypography>
              <Divider sx={{ mt: 2, mb: 2, borderColor: "#4caf50" }} />
              <MKTypography variant="body2" color="white">
                <Box sx={{ fontSize: "0.8rem", maxWidth: "200px" }}>
                  <ul>
                    <li> GESTION DE BASE DE DATOS </li>
                    <li> PRODUCCIÓN AUDIO VISUAL </li>
                    <li> DESARROLLO Y MODELAMIENTO DE SOFTWARE </li>
                    <li> PLATAFORMAS DE COMUNICACIÓN DE MASAS POR INTERNET </li>
                  </ul>
                </Box>
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={2.7}>
              <MKTypography variant="h4" color="white">
                Ciclo
              </MKTypography>
              <MKTypography variant="h1" color="white" sx={{ mt: 1 }}>
                04
              </MKTypography>
              <Divider sx={{ mt: 2, mb: 2, borderColor: "#4caf50" }} />
              <MKTypography variant="body2" color="white">
                <Box sx={{ fontSize: "0.8rem", maxWidth: "200px" }}>
                  <ul>
                    <li> DISEÑO GRAFICO PARA VIDEOS 3D </li>
                    <li> DISEÑO DE PLATAFORMAS EDUCATIVAS </li>
                    <li> COMUNITY MANAGER </li>
                    <li> GESTIÓN CON INTELIGENCIA ARTIFICIAL </li>
                  </ul>
                </Box>
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
        <Box mt={15} />
      </MKBox>
    </MKBox>
  );
}

export default Section5;
