import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Section6() {
  const lugares = [
    {
      nombre: "Sede central - San Isidro",
      direccion: "Calle Manuel Fuentes 420 - Lima",
      mapaUrl:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.2076305270393!2d-77.03309679071619!3d-12.097933688093443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c8688a823357%3A0x7b04e5349cca8db7!2sCa.%20Manuel%20A.Fuentes%20420%2C%20Lima%2015046!5e0!3m2!1ses-419!2spe!4v1708616129635!5m2!1ses-419!2spe",
    },
    {
      nombre: "Lince",
      direccion: "Av. Gral. Trinidad Morán 269 - Lima",
      mapaUrl:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11826.527029581199!2d-77.04190666947855!3d-12.091654312138324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c9c9c7e3e079%3A0x589b2fab2992dc5b!2sAv.%20Gral.%20Trinidad%20Mor%C3%A1n%20269%2C%20San%20Isidro%2015073!5e0!3m2!1ses-419!2spe!4v1708616560136!5m2!1ses-419!2spe",
    },
    {
      nombre: "Provincia - Huancayo",
      direccion: "Chupaca",
      mapaUrl:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15607.072346122766!2d-75.29261863064148!3d-12.059471410061507!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x910e959759f85b9f%3A0xff3c15e597b3d50a!2sChupaca!5e0!3m2!1ses-419!2spe!4v1708617338920!5m2!1ses-419!2spe",
    },
  ];

  const [mapaUrl, setMapaUrl] = React.useState(lugares[0].mapaUrl);

  const handleMostrarMapa = (url) => {
    setMapaUrl(url);
  };

  return (
    <MKBox component="section" my={-11} py={6} position="relative" overflow="hidden">
      {/* Fondo con esquina redonda */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "350px", // Define la altura deseada
          borderRadius: "0 0 180px 0", // Esquina redonda en la esquina superior izquierda
          zIndex: -1,
        }}
      />
      <Container>
        <Grid container>
          {/* Primera Columna */}
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                border: "1px solid black",
                borderRadius: "30px",
                padding: "20px",
                backgroundColor: "rgb(0, 40, 85)",
                width: "100%",
              }}
            >
              <Box my={5}>
                <MKTypography variant="h2" color="white" textAlign="center">
                  Nuestras sedes
                </MKTypography>
              </Box>
              {lugares.map((lugar, index) => (
                <Box
                  key={index}
                  sx={{
                    border: "1px solid black",
                    borderRadius: "20px",
                    padding: "20px",
                    marginBottom: "20px",
                    backgroundColor: "rgb(0, 40, 85)",
                    "&:hover": {
                      backgroundColor: "rgb(0, 65, 138)",
                    },
                  }}
                >
                  <Box my={1}>
                    <MKTypography variant="h5" color="white" textAlign="center" my={2}>
                      {lugar.nombre}
                    </MKTypography>
                    <MKTypography variant="h6" color="white" textAlign="center">
                      {lugar.direccion}
                    </MKTypography>
                  </Box>
                  <Box textAlign="center">
                    <button
                      onClick={() => handleMostrarMapa(lugar.mapaUrl)}
                      style={{
                        borderRadius: "10px", // Esquinas redondeadas
                        backgroundColor: "rgb(0, 40, 85)", // Color de fondo
                        color: "white", // Color del texto
                        padding: "10px 20px", // Espacio interno
                        border: "none", // Sin borde
                        cursor: "pointer", // Cursor al pasar por encima
                        transition: "background-color 0.3s", // Transición suave del color de fondo
                      }}
                    >
                      Ver la ubicación
                    </button>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
          {/* Segunda Columna */}
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                width: "100%", // Modificar el ancho según sea necesario
                height: "100%", // Modificar la altura según sea necesario
                mb: 4,
                padding: "20px",
              }}
            >
              {mapaUrl && (
                <iframe
                  title="Mapa de Google Maps"
                  src={mapaUrl}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen
                ></iframe>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box mt={25} />
    </MKBox>
  );
}

export default Section6;
