import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import icon1 from "assets/images/logo-ct-dark.png";

function Section4() {
  return (
    <MKBox component="section" pt="3rem" pb="8rem">
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <MKTypography variant="h2" mb={2} mt={0}>
            Convenios
          </MKTypography>
          <Box
            sx={{
              position: "absolute",
              left: "50%",
              bottom: "-8px",
              transform: "translateX(-50%)",
              width: "50px",
              height: "8px",
              backgroundColor: "rgb(105, 190, 40)",
              borderRadius: "12px",
            }}
          />
        </Box>
      </Container>
      <Container>
        <Grid container spacing={2} justifyContent="center" my={5}>
          {[1, 2, 3, 4].map((item) => (
            <Grid item xs={12} sm={6} md={3} key={item}>
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: "1rem",
                  borderRadius: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.9)",
                  marginBottom: "1rem", // Espaciado inferior
                }}
              >
                <img
                  src={icon1}
                  alt={`Icon ${item}`}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "6rem", // Tamaño máximo ajustado en rem
                    marginBottom: "1rem", // Espaciado inferior
                  }}
                />
                <MKTypography variant="h6" color="black" mb={1} textAlign="center">
                  CONVENIO CON
                </MKTypography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Section4;
